:root {
    --primary-color: #007bc1;
    --border-color: #004b75;
    --hover-color: #2485bc;
}

#okta-sign-in .default-custom-button {
    background-color: var(--primary-color);
    border-color: var(--border-color);
    text-decoration: none;
}

#okta-sign-in .default-custom-button:hover {
    background-color: var(--hover-color);
    border-color: var(--border-color);
}

#okta-sign-in .auth-org-logo {
    max-height: 125px;
    margin-left: -42px;
}
